import React from 'react'
import { graphql } from 'gatsby'
import HeroBanner from '../components/HeroBanner'
import Layout from '../components/Layout'
import './blog.scss'
import Image from '../components/Image'

const Blog = ({ data }) => {
  const post = data.wordpressPost
  const image = post.featured_media ? post.featured_media.localFile.childImageSharp.fluid : null
  return (
    <Layout>
      <div className="Blog">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OCR Blog"
          caption="Ramblings from OCR Academy"
        />
        <div className="container content">
          {post.featured_media ? (<div className="blogImage"><Image fluid={image} alt={post.title} /></div>) : null }
          <h2>{post.title}<sub>by {post.author.name} ({post.date})</sub></h2>
          <div className="blogContent" dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query Blog($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      content
      author {
        name
      }
      date(formatString: "MMMM Do, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
